import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { PeriodeService } from '@app/services/periode.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { PeriodeRubrique, PeriodeActivite, ReservationPeriode, HoursData, PeriodeDay, Periode } from '@app/models/periode';
import { PlatformService } from '@app/services';
import { ReservationPresence, PresenceError, Reservation, PresenceState } from '@app/models/reservation';
import { take } from 'rxjs/operators';
import { ActivityWithDay, PlanningService, DATE_FORMAT } from '@app/services/planning.service';
import { PlanningData, ReservationConsumer } from '../planning-data';
import { PresenceRepeatComponent, RepeatConfig } from '../presence-repeat/presence-repeat.component';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

interface Selectable {
  selected?: boolean;
  existing?: boolean;
  disabled?: PresenceError;
  errorMessage?: string;
  attente?: boolean;
}

interface RubriqueCustomHours extends HoursData {
  startError?: string;
  endError?: string;
}

interface SelectRubrique extends PeriodeRubrique, Selectable {
  activities?: SelectActivity[];
  rooms?: number;

  recurrencyMessage?: string;

  otherConsumers?: string; // only comma joined consumers names, for now
  otherAccountConflict?: boolean;

  userHours?: RubriqueCustomHours;
}

interface SelectActivity extends ActivityWithDay, Selectable {
  rubriques?: SelectRubrique[];
  autoMessage?: string;

  selectedRubrique?: number; // @TODO: fill with existing Presence rubrique (if any)
  multiRubriquesText?: string;

  // For "grouped" activities
  grouped?: ActivityWithDay[];
  groupMessage?: string;
  datetimeDetails?: string;
}

@Component({
  selector: 'app-planning-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss']
})
export class PlanningSelectDialogComponent implements OnInit {

  @ViewChild(MatTabGroup) tabRoot: MatTabGroup;
  @ViewChild('activitiesContainer') activitiesContainer: ElementRef;

  data: PlanningData;
  date: moment.Moment;
  dateStr: string;
  editMode: 'readonly' | 'admin'; // null means 'normal' edit mode ..

  rubriques: SelectRubrique[];
  activities: SelectActivity[];
  activitiesAutoOnRecurrencePresences: SelectActivity[] = [];
  rubriqueChains: number[][];

  // @NB: Only Presences (not cancels) for current date & current child
  // Could keep this in PlanningData and keep up to date with a "onPresenceUpdate()" listener
  currentConsumerPresences: ReservationPresence[];
  currentConsumerAndPeriodePresences: ReservationPresence[];

  hasError = false;
  tabIndex = 0;

  periode: ReservationPeriode;

  liveCapacity: boolean;

  formValid = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData,
    private dialog: MatDialog,
    private elementRef: ElementRef,
    private dialogRef: MatDialogRef<any>,
    private periodeService: PeriodeService,
    private planningService: PlanningService,
    private translation: TranslateService,
    public platformService: PlatformService
  ) {
    this.date = moment(dialogData.date);
    this.dateStr = this.date.format(DATE_FORMAT);
    this.data = dialogData.planningData;
    this.editMode = dialogData.editMode;
  }

  ngOnInit() {
    this.periode = this.data.currentPeriode as ReservationPeriode;
    this.rubriqueChains = this.periode.rubriqueChains;

    this.liveCapacity = this.editMode !== 'admin' && this.periode.liveCapacity;

    this.currentConsumerPresences = this.planningService.filterPresences(this.data.getCurrentConsumerPresences())
      .filter(pr => pr.date === this.dateStr);

    this.currentConsumerAndPeriodePresences = this.planningService.filterPresences(this.data.getCurrentConsumerAndPeriodePresences())
      .filter(pr => pr.date === this.dateStr);

    this.setupRubriques();
    this.setupActivities();

    this.setupRubriqueActivityLinks();

    this.setDisabled();

    if (this.dialogData.accessActivity) {
      this.tabIndex = 1;
    }
  }

  setupRubriques() {
    const periodeDay = this.periode.days.find(pd => pd.date === this.dateStr);

    const otherConsumerPresences = [].concat(...this.data.reservations.filter(r => !this.data.isCurrentConsumer(r.idConsumer))
      .map(r => this.planningService.filterPresences(r.presences).filter(pr => pr.date === this.dateStr)));

    const otherAccountPresences = this.currentConsumerPresences.filter(pr => this.data.findReservation(pr.reservation)?.otherAccount);

    // We preset compatible activities to each rubrique, to avoid many getRubriqueActivities() calls in template
    this.rubriques = this.getAvailableRubriques(periodeDay).map(rub => {
      const selectRubrique: SelectRubrique = {
        ...rub,
        existing: this.currentConsumerAndPeriodePresences.some(pr => pr.rubrique === rub.id),
        recurrencyMessage: this.getRubriqueRecurrencyMessage(rub),
        otherConsumers: this.getOtherConsumersList(rub, otherConsumerPresences),
        otherAccountConflict: this.hasRubriqueConflictWithPresences(rub, otherAccountPresences).length > 0
      };

      if (rub.customStartTime || rub.customEndTime) {
        selectRubrique.userHours = {
          start: rub.customStartTime ? rub.horaires[0].start : null,
          end: rub.customEndTime ? rub.horaires[rub.horaires.length - 1].end : null // end of the day
        };
      }

      if (this.liveCapacity) {
        selectRubrique.rooms = periodeDay.dispos ? this.planningService.getRubriqueRooms(rub, periodeDay.dispos) : 0;
      }

      return selectRubrique;
    });
  }

  setupActivities() {
    const activitiesWithDay = this.planningService.getActivitiesWithDay(this.data.enabledActivities);

    // Activities, with day info and compatible rubriques list (same reason as above)
    this.activities = activitiesWithDay.filter(ad => ad.date === this.dateStr).map(act => {
      const selectActivity: SelectActivity = {
        ...act,
        autoMessage: this.getAutoActivityMessage(act),
      };

      if (act.group) {
        selectActivity.grouped = this.planningService.getGroupedActivities(act, activitiesWithDay);
        selectActivity.groupMessage = this.getGroupActivityMessage(selectActivity);
        selectActivity.datetimeDetails = this.getGroupActivityDatetimeDetails(selectActivity);
      }

      const existing = this.currentConsumerAndPeriodePresences.find(pr => pr.activities && pr.activities.includes(act.id));

      if (existing) {
        selectActivity.existing = true;
        selectActivity.selectedRubrique = existing.rubrique;
      }

      return selectActivity;
    });

    // Sort activities by date & time
    this.activities.sort((ad1, ad2) => {
      const firstTime = ad1.date + ad1.startTime + ad1.endTime;
      const secondTime = ad2.date + ad2.startTime + ad2.endTime;

      return firstTime === secondTime ? 0 : (firstTime > secondTime ? 1 : -1);
    });
  }

  setupRubriqueActivityLinks() {
    // Assign link between Rubriques & Activities
    this.rubriques.forEach(rub => {
      rub.activities = this.activities.filter(act => this.matchRubriqueActivity(rub, act));
    });

    this.activities.forEach(act => {
      act.rubriques = this.rubriques.filter(rub => this.matchRubriqueActivity(rub, act));
      if (act.rubriques.length > 1) {
        act.multiRubriquesText = act.rubriques.map(rub => rub.label || rub.name).join('\n');
      } else if (act.rubriques.length === 1) {
        // Fix little bug : when rubrique is not defined on the 'activity day' but only one rubrique
        //  is available => can't validate this dialog : error in createPresencesForSelection() because no rubrique found
        act.rubrique = act.rubriques[0].id
      }
    });
  }

  setDisabled() {
    const fullIsError = this.liveCapacity && !this.periode.gestionListeAttente;

    this.rubriques.forEach(rub => rub.disabled = this.getRubriqueError(rub, fullIsError));

    this.activities.forEach(act => {
      // Check every activity of the group for error (except if set as "flexible")
      const grouped = act.grouped?.length && !act.datesFlexible ? act.grouped : [act];

      for (const ga of grouped) {
        const error = this.getActivityError(ga, fullIsError);

        if (error) {
          act.disabled = error;
          break;
        }
      }
    });

    this.updateConflicts();
  }

  getRubriqueError(rub: SelectRubrique, checkCapacity: boolean): PresenceError {
    // @TODO: should probably check as well that every recurrency date is editable & has room ?

    if (!this.data.isEditableDate(this.dateStr)) {
      return 'date';
    }

    // Skip capacity check if we're in admin
    if (checkCapacity && rub.rooms < 1) {
      return 'full';
    }

    return null;
  }

  getActivityError(act: ActivityWithDay, checkCapacity: boolean) {
    if (!this.data.isEditableDate(act.date as string)) {
      return 'date';
    }

    if (checkCapacity && act.dispos < 1) {
      return 'full';
    }

    return null;
  }

  getAvailableRubriques(periodeDay: PeriodeDay) {
    const weekDay = this.date.isoWeekday();

    // Exclude rubrique according to "RubriqueConfig.openDays" or "PeriodeDay.rubriquesExclues" option
    return this.periode.rubriques.filter(rub => {
      return rub.enabled
        && (!rub.openDays || rub.openDays.includes(weekDay))
        && (periodeDay && !periodeDay.rubriquesExclues?.includes(rub.id));
    });
  }

  matchRubriqueActivity(rubrique: PeriodeRubrique, activity: ActivityWithDay) {
    // We don't check activity plages if has a specific ID set
    return activity.rubrique ? activity.rubrique === rubrique.id : this.periodeService.plagesMatch(rubrique.plages, activity.plages);
  }

  // Might want to replace this, think about live updating the "newPresences" array, and check conflict on it ..
  updateConflicts() {
    // No need to check "existing" (already reserved) rubriques
    const rubriques = this.rubriques.filter(rub => !rub.existing && (!rub.disabled || ['conflict', 'chain'].includes(rub.disabled)));

    // Reset errors that will be rechecked
    rubriques.forEach(rub => rub.disabled = null);

    if (!this.periode.allowSuperposedPresences) {
      rubriques.forEach(rub => {
        this.checkForRubriqueConflict(rub)
      });
    }

    // Could be done in the loop above, but well ...
    this.updateChainsValidity();

    const activities = this.activities.filter(act => !act.existing && (!act.disabled || ['conflict', 'rubrique'].includes(act.disabled)));

    // Reset errors that will be rechecked
    activities.forEach(act => act.disabled = null);

    activities.forEach(act => {

      // Check for conflict among other activities of the group
      if (!this.periode.allowSuperposedPresences) {
        const grouped = act.grouped?.length ? act.grouped : [act];
        for (const ga of grouped) {
          if (this.checkForActivityConflict(ga, this.activities, this.currentConsumerPresences)) {
            act.disabled = 'conflict';
          }
        }
      }

      if (act.auto && act.selected) {
        const selectedRubrique = this.rubriques.find(r => r.id === act.selectedRubrique);
        selectedRubrique.activities.forEach(a => a.disabled = 'conflict');
      }

      // Special case for Activity attached to a not-enabled-in-config (/hidden) Rubrique
      const associatedRubrique = act.rubrique ? this.periode.rubriques.find(r => r.id === act.rubrique) : null;

      // If the attached rubrique is hidden (standalone activity), no need to check the rubrique
      if (!(associatedRubrique && !associatedRubrique.enabled)) {

        // Disable if no rubrique is available
        if (!act.rubriques.some(r => !r.disabled && !r.existing)) {
          act.disabled = 'rubrique';
        }
      }
    });

    // At the end, we need to toggle off all previously selected items that are now disabled (this may be moved to a separate function)
    // @NB: calling "toggle" functions will make all linked items repass through the "updateConflicts" (a bit heavy but should work)
    this.rubriques.filter(r => r.selected && r.disabled).forEach(r => this.toggleRubrique(r));
    this.activities.filter(a => a.selected && a.disabled && !a.auto).forEach(a => this.toggleActivity(a));
  }

  // Looks ok. For the record, here in select chain behavior is like :
  // Disable rubriques that have "requiredRubriques" (means rubrique before it in chain) that are not checked (existing or selected)
  updateChainsValidity() {
    // Chains free
    if (!this.rubriqueChains?.length) {
      return;
    }

    const selectedRubriques = this.rubriques.filter(r => r.selected || r.existing);
    const handleAttente = this.data.currentPeriode.liveCapacity && !!this.data.currentPeriode.gestionListeAttente;

    this.rubriques.forEach(rubrique => {
      rubrique.attente = false;

      // Get chains where this rubrique appears, only get the part before the rubrique
      const chains = this.planningService.getRubriqueChainsPart(this.rubriqueChains, rubrique.id, 'before');

      if (!chains.length) {
        return;
      }

      // Get chains elements corresponding to each chain
      const candidates = chains.map(ch => {
        const items = this.planningService.getChainItems(ch, selectedRubriques, this.currentConsumerAndPeriodePresences);
        return this.getChainItemsState(ch, items, handleAttente);
      });

      // If no chain candidate is compliant, disable
      if (!candidates.some(c => !!c)) {
        rubrique.disabled = 'chain';
      } else if (handleAttente && !candidates.some(c => c === true)) {
        // If no chain candidate is available without 'attente' state, this one should be on attente too
        rubrique.attente = true;
      }
    });
  }

  hasRubriqueConflictWithPresences(rubrique: PeriodeRubrique, presences: ReservationPresence[]): ReservationPresence[] {
    return presences.filter(pr => this.planningService.checkTimesOverlap(rubrique.horaires, this.planningService.getPresenceHours(pr)));
  }

  checkForRubriqueConflict(rubrique: SelectRubrique) {
    // Check for conflict among existing presences
    let conflictPres = this.hasRubriqueConflictWithPresences(rubrique, this.currentConsumerPresences)
    if (conflictPres.length > 0) {
      rubrique.disabled = 'conflict';
      // rub.errorMessage = 'reservation.error_type.conflict'
      let errorMessage = this.translation.instant('reservation.error_type.conflict');
      errorMessage += ' :\n' + conflictPres.map(cpr => cpr.startTime + ' - ' + cpr.endTime + ' : ' + cpr.title).join('\n');

      rubrique.errorMessage = errorMessage
      return 'presence';
    }

    // Every selected rubriques (excluding current)
    const selectedRubriques = this.rubriques.filter(rub => rub.selected && rub.id !== rubrique.id);

    // Check that given Rubrique has conflict with any of "selected" Rubriques
    if (selectedRubriques.some(check => this.planningService.checkTimesOverlap(check.horaires, rubrique.horaires))) {
      rubrique.disabled = 'conflict';
      return 'select';
    }

    return null;
  }

  checkForActivityConflict(activity: ActivityWithDay, activitiesOfDay: SelectActivity[], currentConsumerPresences: ReservationPresence[]) {
    // - first check among selected activities if has hours conflict
    if (activitiesOfDay.some(a => a.selected && a.id !== activity.id && this.planningService.checkTimesOverlap(activity._computedHours, a._computedHours))) {
      return true;
    }

    // - then get all conflicting Presences (should be only one max, but who knows ...)
    const conflicts = currentConsumerPresences.filter(pr =>
      pr.date === activity.date && this.planningService.checkTimesOverlap(activity._computedHours, this.planningService.getPresenceHours(pr))
    );

    if (conflicts.length) {
      // - if activity is "youth mode" (rubrique + hours) => conflict (we need to create a new presence)
      if (activity.rubrique && activity.startTime) {
        return true;
      }

      // - else return true if presence is not editable (has ID) or has conflicting activities
      if (conflicts.some(c => c.id || (c.activities?.length && this.checkPresenceActivitiesConflict(c, activity)))) {
        return true;
      }
    }

    return false;
  }

  checkPresenceActivitiesConflict(pr: ReservationPresence, activity: ActivityWithDay) {
    return this.planningService.checkTimesOverlap(activity._computedHours, this.planningService.getPresenceHours(pr));
  }

  toggleRubrique(rubrique: SelectRubrique) {
    if (rubrique.selected) {
      rubrique.selected = false;

      // Unselect activities that were selected on this Rubrique
      this.activities.filter(a => a.selected && a.selectedRubrique === rubrique.id).forEach(a => this.toggleActivity(a));
    } else {
      rubrique.selected = true;

      // Auto select "auto" (= forced) activities
      rubrique.activities.filter(a => a.auto && !(a.selected || a.existing)).forEach(a => this.toggleActivity(a, rubrique.id));
    }

    this.updateConflicts();
  }

  toggleActivity(activity: SelectActivity, rubrique?: number) {
    if (activity.selected) {
      activity.selected = false;
      activity.selectedRubrique = null;

      // @TODO: re-enable auto unselect associated rubrique (based on "forActivity") ?
      // if (!toggle && (activity.auto || this.selection[rubrique].forActivity && !this.hasActivitiesSelectedForRubrique(rubrique)))
    } else {
      activity.selected = true;
      activity.selectedRubrique = rubrique || activity.rubrique; // = activity.rubriques[0].?id

      const attachedRubrique = this.rubriques.find(r => r.id === activity.selectedRubrique);

      // auto select the associated rubrique (might be not enabled, hidden from Rubriques list ..)
      if (attachedRubrique) {
        attachedRubrique.selected = true;
      }
    }

    this.updateConflicts();
  }

  createPresencesForSelection() {
    const presences: ReservationPresence[] = [];

    // Should be already done automatically, but just to be sure ...
    this.activities.filter(a => a.auto && a.rubriques?.some(r => r.selected)).forEach(a => a.selected = true);

    // Try a new trick : create Activities presences first
    this.activities.filter(a => a.selected && (!a.disabled || a.auto)).forEach(act => {
      const group = act.grouped?.length ? act.grouped : [act];

      // Ensure every date of grouped activities is editable
      if (!act.datesFlexible && group.some(ga => !this.data.isEditableDate(ga.date))) {
        return; // skip activity, but maybe rather throw error ?
      }

      // const rubrique = this.data.findRubrique(act.selectedRubrique, this.periode);
      // const rubrique = this.periode.rubriques.find(r => r.id === act.selectedRubrique);
      const rubrique = this.rubriques.find(r => r.id === act.selectedRubrique) || this.periode.rubriques.find(r => r.id === act.selectedRubrique);

      const idGroup = group?.length > 1 ? this.planningService.generateId() : null;

      group.filter(ga => this.data.isEditableDate(ga.date)).forEach(ga => {
        let actPresence: ReservationPresence;
        // "Mode jeunesse" : one Presence per activity
        if (!(ga.rubrique && ga.startTime)) {
          // Else : try to add Activity to an existing (but editable) Presence
          actPresence = presences.find(pr => pr.date === ga.date && pr.rubrique === rubrique.id)
            || this.data.currentReservation.presences.find(pr => !pr.askCancel && pr.date === ga.date && pr.rubrique === rubrique.id);
        }
        if (actPresence) {
          // @TODO: should still check that activity hours don't conflict
          this.planningService.addActivityToPresence(actPresence, ga);
        } else {
          actPresence = this.planningService.createActivityPresence(ga, rubrique, ga.date, (rubrique as any).userHours);
          actPresence.group = idGroup;
          presences.push(actPresence);
        }
      });
    });

    this.rubriques.filter(r => r.selected && !r.disabled).forEach(rub => {
      const recurrencyDates = this.planningService.getLinkedDates(this.dateStr, rub, this.periode);

      const idGroup = recurrencyDates.length > 1 ? this.planningService.generateId() : null;

      recurrencyDates.forEach(date => {
        // @NB: still a pending question : any need to block if a recurrency date is out of bounds ?
        // New behavior : avoid recreating "new presence" for Rubrique (one might have just been created for Activity)
        if (this.data.isEditableDate(date) && !presences.some(pr => pr.date === date && pr.rubrique === rub.id)) {
          const prez = this.planningService.createPresenceFromRubrique(rub, date, rub.userHours);
          prez.group = idGroup;
          presences.push(prez);
        }
      });
    });

    if (this.periode.modeSelection !== "free") {
      // Vérifier et ajouter les activités obligatoires pour chaque date de présence créée
      presences.forEach(prez => {
        // Chercher les activités obligatoires (auto) sur la même date que la présence
        const mandatoryActivities: SelectActivity[] = this.planningService.getActivitiesWithDay(this.data.enabledActivities)
          .filter(act => act.auto && act.date === prez.date);

        mandatoryActivities.forEach(mandatoryAct => {

          mandatoryAct.rubriques = this.rubriques.filter(rub => this.matchRubriqueActivity(rub, mandatoryAct));

          if ((mandatoryAct.rubrique === prez.rubrique) || (mandatoryAct.rubriques?.some(rub => rub.id === prez.rubrique))) {
            // Vérifier si l'activité obligatoire est déjà incluse
            if (!prez.activities?.some(act => act === mandatoryAct.id)) {
              mandatoryAct.selected = true;
              this.activitiesAutoOnRecurrencePresences.push(mandatoryAct);
              this.planningService.addActivityToPresence(prez, mandatoryAct);
            }
          }
        });
      });
    }

    return presences;
  }

  validate(recopyConfig?: RepeatConfig) {
    const newPresences: ReservationPresence[] = this.createPresencesForSelection();

    // Last check for errors part
    this.refreshErrors(newPresences);

    if (this.hasError) {
      this.scrollToError();
      return;
    }

    this.dialogRef.close({ presences: newPresences, recopy: recopyConfig });
  }

  refreshErrors(presences: ReservationPresence[]) {
    // Reset
    this.hasError = false;
    this.rubriques.forEach(r => r.errorMessage = null);
    this.activities.forEach(a => a.errorMessage = null);

    const otherPresences = presences.concat(this.planningService.filterPresences(this.data.getCurrentConsumerPresences()));
    const ignoreCapacity = this.editMode === 'admin' || !!this.periode.gestionListeAttente;

    for (const pr of presences) {
      let error: PresenceError;

      if (pr.activities?.length && this.activities?.length) {
        const acts = pr.activities.map(a => this.activities.find(aa => aa.id === a));

        if (acts.some((act => this.checkForActivityConflict(act, this.activities, this.currentConsumerPresences)))) {
          error = 'activity_conflict';
        }
      }

      if (this.activitiesAutoOnRecurrencePresences?.length) {

        const actsDay = this.activitiesAutoOnRecurrencePresences.filter(act => act.date === pr.date);

        if (actsDay?.length && pr.activities?.length) {
          const acts = pr.activities.map(a => actsDay.find(aa => aa.id === a));

          let actError = acts.find((act => this.getActivityError(act, ignoreCapacity)));
          if (actError) {
            error = this.getActivityError(actError, ignoreCapacity);
          }

          const currentConsumerPeriodePresence = this.planningService.filterPresences(this.data.getCurrentConsumerAndPeriodePresences())
            .filter(prez => prez.date === pr.date);

          if (acts.some((act => this.checkForActivityConflict(act, actsDay, currentConsumerPeriodePresence)))) {
            error = 'activity_conflict';
          }
        }
      }

      if (!error) {
        error = this.planningService.checkPresenceError(pr, otherPresences, this.periode, ignoreCapacity);
      }

      if (error) {
        let errorMessage = this.translation.instant('reservation.error_type.' + error);

        // if (error === 'activity_conflict' && this.activitiesAutoOnRecurrencePresences?.length && !this.activities?.length) {
        //   errorMessage += ' :\n' + `le ${moment(pr.date).format('DD-MM-Y')}`
        // }

        if (error === "full" && this.activitiesAutoOnRecurrencePresences?.length) {
          errorMessage += ' :\n' + `le ${moment(pr.date).format('DD-MM-Y')}`
        }

        if (error === 'conflict') {
          const conflictsWith = this.planningService.getConflicts(pr, otherPresences);

          errorMessage += ' :\n' + conflictsWith.map(cpr => cpr.title + ' du ' + cpr.date).join('\n');
        }

        const rubrique = this.rubriques.find(r => r.id === pr.rubrique);
        const activities = this.activities.filter(a => pr.activities?.includes(a.id));

        if (rubrique) {
          rubrique.errorMessage = errorMessage;
        }

        if (activities?.length) {
          activities.forEach(a => a.errorMessage = errorMessage);
        }

        this.hasError = true;
      }
    }
  }

  // // --- UI --- //

  /**
   * Donne le texte d'information de récurrence (affiché dans une carte sous la Rubrique)
   * @param rubrique PeriodeRubrique
   */
  getRubriqueRecurrencyMessage(rubrique: PeriodeRubrique) {
    const selectedDate = moment(this.date);

    switch (rubrique.modeSelection) {
      case 'week':
        const from = selectedDate.clone().isoWeekday(1);
        const until = selectedDate.clone().isoWeekday(7);
        return `<b>chaque jour ouvert de la semaine ${from.format('W')} (du ${from.format('DD/MM')} au ${until.format('DD/MM')})</b>`;
      case 'month':
        return `<b>chaque jour ouvert du mois de ${selectedDate.format('MMMM Y')}</b>`;
      case 'day': return `<b>chaque ${selectedDate.format('dddd')} de la période</b>`;
      case 'period': return '<b>chaque jour ouvert de la période entière</b>';
      default: return '';
    }
  }

  getOtherConsumersList(rubrique: PeriodeRubrique, otherConsumersPresences: ReservationPresence[]) {
    const sameRubriquePresences = otherConsumersPresences.filter(pr => pr.rubrique === rubrique.id);

    const consumers: ReservationConsumer[] = [];

    sameRubriquePresences.forEach(pr => {
      const resa = this.data.findReservation(pr.reservation);
      const consumer = this.data.findConsumer(resa.idConsumer);

      if (consumer) {
        if (!consumers.some(e => e.id === consumer.id)) {
          consumers.push(consumer);
        }
      }
    });

    if (consumers.length) {
      return consumers.map(c => c.prenom + ' ' + c.nom).join(', ');
    }

    return null;
  }

  getAutoActivityMessage(activity: ActivityWithDay) {
    // no message if no fixed rubrique ? :o
    if (activity.auto && activity.rubrique) {
      // const rub = this.data.findRubrique(activity.rubrique, this.periode);
      const rub = this.periode.rubriques.find(r => r.id === activity.rubrique);
      if (rub.enabled) {
        return `Cette activité est liée à la rubrique ${rub.label || rub.name}`;
      }
    }

    return null;
  }

  getGroupActivityMessage(activity: SelectActivity) {
    if (activity.grouped && activity.grouped.length) {
      let message = 'Cette activité se déroulera sur plusieurs jours :\n';
      message += activity.grouped.map(ad => '- ' + moment(ad.date).format('L')).join('\n');
      return message;
    }

    return null;
  }

  getGroupActivityDatetimeDetails(activity: SelectActivity) {
    if (activity.group) {
      const session = activity.sessions.find(sess => sess.codeGroupe === activity.group);

      if (session && session.datetimeDetails) {
        return session.datetimeDetails;
      }
    }

    return null;
  }

  accessActivity(activity: ActivityWithDay, e: Event) {
    e.preventDefault();

    this.tabRoot.selectedTabChange.pipe(take(1)).subscribe(() => this.scrollToActivity(activity));

    this.tabIndex = 1;
  }

  scrollToActivity(activity: PeriodeActivite) {
    const id = typeof activity === 'object' ? activity.id : activity;

    // Let's try to scroll to clicked activity
    const scroller = this.activitiesContainer.nativeElement as HTMLElement;
    // A bit hacky .. but should work
    const target = scroller.querySelector('.activity-card[data-acty-id="' + id + '"]');

    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToError() {
    const rootElement = this.elementRef.nativeElement as HTMLElement;
    // Scroll back to top of Dialog (after UI has refreshed), to display the error message
    setTimeout(() => {
      const errorElement = rootElement.querySelector('.error-message');

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  /**
   * Repeat selection on some other dates
   */
  openRepeatOptions() {
    const repeatDialog = this.dialog.open(PresenceRepeatComponent, {
      data: {
        periode: this.periode,
        date: this.date.format(DATE_FORMAT),
        planningData: this.data,
        minDate: this.data.getRealFirstEditableDate(),
        presetPresences: true
      }
    });

    repeatDialog.afterClosed().subscribe((result: RepeatConfig) => {
      if (result) {
        this.validate(result);
      }
    });
  }

  onChangeCustomTime(rubrique: SelectRubrique) {
    if (rubrique.userHours) {
      rubrique.userHours.startError = this.getCustomTimeError(rubrique.userHours.start, rubrique.horaires[0]);
      rubrique.userHours.endError = this.getCustomTimeError(rubrique.userHours.end, rubrique.horaires[rubrique.horaires.length - 1]);
    }

    this.refreshFormValidity();
  }

  getCustomTimeError(customHour: string, referenceHours: HoursData) {
    if (customHour < referenceHours.start) {
      return 'Heure minimum : ' + referenceHours.start;
    }

    if (customHour > referenceHours.end) {
      return 'Heure maximum : ' + referenceHours.end;
    }

    return null;
  }

  refreshFormValidity() {
    this.formValid = !this.rubriques.some(r => r.userHours?.startError || r.userHours?.endError);
  }

  getChainItemsState(chain: number[], items: (SelectRubrique | ReservationPresence)[], handleAttente = false) {
    if (items.length !== chain.length) {
      return false;
    }

    if (handleAttente && items.some(item => this.isChainItemAttente(item))) {
      return 'attente';
    }

    return true;
  }

  isChainItemAttente(item: SelectRubrique | ReservationPresence) {
    return item.hasOwnProperty('rubrique') ?
      (item as ReservationPresence).status === 'liste_attente' :
      (item as SelectRubrique).rooms < 1 || (item as SelectRubrique).attente;
  }
}
